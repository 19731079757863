import React, {Fragment, useContext, useEffect, useState} from 'react'
import useRequest from '../../../../../hooks/useRequest'
import globalStates from "../../../../../context/globalStates";
const integrationJobList = require('../../../../../sources/integrationJobList.json');
const integrationSectorList = require('../../../../../sources/integrationSectorList.json');

const EpisodeThree = (props) => {

    const [monthlyIncome, setMonthlyIncome] = useState("")
    const [fortune, setFortune] = useState("")
    const [job, setJob] = useState("")
    const [titles, setTitles] = useState([])
    const [source, setSource] = useState("")
    const [experience, setExperience] = useState(0)
    const [danger, setDanger] = useState(false)
    const { startFetch, response, loading } = useRequest()
    const { innerTexts } = useContext(globalStates);
    const [manuelJob, setManuelJob] = useState("")
    const [manuelJobInput, setManuelJobInput] = useState(false)
    const [wrongJob, setWrongJob] = useState(false)

    // integration states
    const [integrationJob, setIntegrationJob] = useState("");
    const [integrationSector, setIntegrationSector] = useState("");
    const [integrationWorkplaceName, setIntegrationWorkplaceName] = useState("");

    useEffect(() => {
        setWrongJob(manuelJob.toLocaleLowerCase().includes("diğer") || manuelJob.toLocaleLowerCase().includes("diger") || manuelJob.toLocaleLowerCase().includes("serbes"))
    }, [manuelJob])
    
    const SaveForm = () => {
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        if (innerTexts.manuelJobEntry === true && manuelJobInput && manuelJob === "") {
          setDanger(true);
          return;
        }

        if (innerTexts.id === 'HEDEF') {
            if (!integrationWorkplaceName || !integrationJob || !integrationSector) {
                setDanger(true);
                return;
            }

            var params = "";

            var model = {
                islem: "yatirimci_bilgileri_diger",
                step: 3,
                sirket_adi: integrationWorkplaceName,
                meslek_bilgisi: integrationJob,
                sektor_bilgisi: integrationSector
            };

            for (var I in model) {
                params += '' + I + '=' + model[I] + '&';
            }

            startFetch(params, "POST");
        }
      
        if (source !== "" && experience > 0) {
          var params = "";
          var model = {
            islem: "gelir_bilgileri",
            step: 3,
            aylik_gelir: monthlyIncome,
            toplam_mal_varligi: fortune,
            mal_varlik_kaynagi: source,
            meslek_tecrube_yili: experience
          };
      
          if (innerTexts.id !== 'MEKSA' && model) {
            if (job !== "") {
              model.meslek = job.includes("diğer") && manuelJob.length > 2 ? manuelJob : job;
              if (innerTexts.id === 'ALNUSYATIRIM') {
                let jobText = manuelJob.toLocaleLowerCase()
                if (jobText.includes("diğer") || jobText.includes("diger") || jobText.includes("serbest")) {
                    setDanger(true);
                    return;
                }
              }

                if (innerTexts.id === 'HEDEF') {
                    model.meslek = ''
                }
            } else {
              setDanger(true);
              return;
            }
          }
      
          for (var I in model) {
            params += '' + I + '=' + model[I] + '&';
          }
      
          startFetch(params, "POST");
        } else {
          setDanger(true);
        }
      };

    useEffect(() => {
      if (job.includes("diğer")) {
        setManuelJobInput(true)
      } else {
        setManuelJobInput(false)
      }
    }, [job])
    

    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.meslekler) {
                    setTitles(response.meslekler)
                } else {
                    if (response.bilgiler) {

                        var data = response.bilgiler
                        setFortune(data.toplam_mal_varligi)
                        setJob(data.meslek)
                        setMonthlyIncome(data.aylik_gelir)
                        setSource(data.mal_varlik_kaynagi)
                        setExperience(data.meslek_tecrube_yili)

                        setIntegrationJob(data?.meslek_bilgisi);
                        setIntegrationSector(data?.sektor_bilgisi);
                        setIntegrationWorkplaceName(data?.sirket_adi);

                    } else {
                        if(innerTexts.id === 'HEDEF') {
                            props.handleWizard.nextStep();
                        } 
                        props.nextStep()
                        setDanger(false);
                    }
                }
            }
        }
    }, [response])

    useEffect(() => {
        startFetch("islem=yatirimci_bilgileri_getir&step=3", "GET")
    }, [])

    useEffect(() => {
        startFetch("islem=meslek_get", "GET")
    }, [])

    // if (props.currentStep !== 3) {
    //     return (
    //         <div></div>
    //     )
    // }
    return (
        <Fragment>
            <div className="container">
                <div className="row">
                <div className='row'>
                        {/*<div className="col-md-6">
                            <div style={{ display: "block", zIndex: 2 }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Aylık Geliriniz</label>
                                </div>
                                <select onChange={(e) => { setMonthlyIncome(e.target.value) }} defaultValue={monthlyIncome} className="form-control form-control-lg">
                                    <option value="null">Seçiniz</option>
                                    <option value="0-5000 TL">₺0 - ₺5.000</option>
                                    <option value="5000-20000 TL">₺5.000 - ₺20.000</option>
                                    <option value="20000 TL ve üzeri">₺20.000+</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div style={{ display: "block" }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Toplam Mal Varlığınız</label>
                                </div>
                                <select onChange={(e) => { setFortune(e.target.value) }} defaultValue={fortune} className="form-control form-control-lg">
                                    <option value="">Seçiniz</option>
                                    <option value="0-50.000">₺0 - ₺50.000</option>
                                    <option value="50.001-100.000">₺50.000 - ₺100.000</option>
                                    <option value="100.001-1.000.000">₺100.000 - ₺1.000.000</option>
                                    <option value="1.000.001-üzeri">₺1.000.000+</option>
                                    <option value="Belirtilmemiş">Belirtilmemiş</option>
                                </select>
                            </div>
                        </div>*/}
                        { innerTexts.id !== 'MEKSA' && innerTexts.id !== 'HEDEF' && <div className="col-md-4">
                            <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Mesleğiniz</label>
                                </div>
                                <select
                                    style={{ border: danger && !job ? '1px solid #eb8787': '', backgroundColor: danger && !job ? '#FFF8F8' : '' }}
                                    onChange={(e) => { setJob(e.target.value) }} value={job?.toLowerCase()} className="form-control form-control-lg">
                                    <option value="" >Seçiniz</option>
                                    {
                                        titles && titles.map((x, i) => {
                                            return (
                                                <option key={i} value={x?.Meslekler?.toLowerCase()}>{x?.Meslekler}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                        </div>}

                        {
                            // 3 fields of Deniz Bank Integration
                            innerTexts.id === 'HEDEF' && (
                                <>
                                    <div className="col-md-4">
                                        <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                            <div className="input-group-prepend">
                                                <label className="control-label">Şirket Adı</label>
                                            </div>
                                            <input defaultValue={""} placeholder='Şirket Adı' style={{ border: danger && !integrationWorkplaceName ? '1px solid #eb8787': '', backgroundColor: danger && !integrationWorkplaceName ? '#FFF8F8' : '' }} onChange={(e) => { setIntegrationWorkplaceName(e.target.value) }} value={integrationWorkplaceName} type='text' className="form-control form-control-lg" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                            <div className="input-group-prepend">
                                                <label className="control-label">Meslek</label>
                                            </div>
                                            <select style={{ border: danger && !integrationJob ? '1px solid #eb8787': '', backgroundColor: danger && !integrationJob ? '#FFF8F8' : '' }} onChange={(e) => { setIntegrationJob(e.target.value) }} value={integrationJob?.toLowerCase()} className="form-control form-control-lg">
                                                <option value="" >Seçiniz</option>
                                                    {
                                                        integrationJobList && integrationJobList.map((x, i) => {
                                                            return (
                                                                <option key={i} value={x?.value}>{x?.text}</option>
                                                            )
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                            <div className="input-group-prepend">
                                                <label className="control-label">Sektör</label>
                                            </div>
                                            <select style={{ border: danger && !integrationSector ? '1px solid #eb8787': '', backgroundColor: danger && !integrationSector ? '#FFF8F8' : '' }} onChange={(e) => { setIntegrationSector(e.target.value) }} value={integrationSector?.toLowerCase()} className="form-control form-control-lg">
                                                <option value="" >Seçiniz</option>
                                                    {
                                                        integrationSectorList && integrationSectorList.map((x, i) => {
                                                            return (
                                                                <option key={i} value={x?.value}>{x?.text}</option>
                                                            )
                                                        })
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        <div className="col-md-4">
                            <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Gelir / Mal Varlığı Kaynağınız</label>
                                </div>
                                <select
                                    style={{ border: danger && !source ? '1px solid #eb8787': '', backgroundColor: danger && !source ? '#FFF8F8' : '' }}
                                    onChange={(e) => { 
                                        setSource(e.target.value) 
                                        sessionStorage.setItem("s3-source", e.target.value)
                                    }}
                                    value={source}
                                    className="form-control form-control-lg"
                                >
                                    <option value={""}>Seçiniz</option>
                                    {innerTexts?.customIncomeSources ?
                                        innerTexts.customIncomeSources.map((x, i) => {
                                            return (
                                                <option key={i} value={x.value}>{x.label}</option>
                                            )
                                        })
                                        :
                                        [
                                            <option value="Ücretli">Maaşlı Çalışan</option>,
                                            <option value="Serbest">Serbest Çalışan</option>,
                                            <option value="Emekli">Emekli</option>,
                                            <option value="Diğer">Diğer</option>,
                                            <option value="Belirtilmemiş">Belirtilmemiş</option>
                                        ]}
                                </select>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Meslek Tecrübe Yılı</label>
                                </div>
                                <input style={{ border: danger && !experience ? '1px solid #eb8787': '', backgroundColor: danger && !experience ? '#FFF8F8' : '' }}  min="0" className="form-control" onChange={(e) => {
                                    if (e.target.value < 0) {
                                        return setExperience(0)
                                    }
                                    sessionStorage.setItem("s3-experience", e.target.value)
                                    setExperience(e.target.value)


                                }} type="number" value={experience} />
                            </div>

                        </div>
                    </div>

                    {
                            innerTexts.manuelJobEntry === true && manuelJobInput &&

                            <div style={{ display: "block", zIndex: 1 }} className="input-group  input-group-lg lg-12">
                                <div className="input-group-prepend">
                                    <label className="control-label">Mesleğinizi yazabilirsiniz:</label>
                                </div>
                                <input
                                        className="form-control" 
                                        type="text"
                                        id="jobInput"
                                        style={{ border: danger && !manuelJob ? '1px solid #eb8787': '', backgroundColor: danger && !manuelJob ? '#FFF8F8' : '' }}
                                        // value={job}
                                        placeholder=''
                                        onChange={(e) => { 
                                            setManuelJob(e.target.value)
                                            sessionStorage.setItem("s3-job", e.target.value)
                                        }}
                                />
                            </div>
                        }


                    <div style={{ marginTop: 20 }} className="col-md-6">
                        <button
                            onClick={() => {
                                if(innerTexts.id === 'TERAYATIRIM') {
                                    props.goToStep(1)
                                } else {
                                    props.previousStep()

                                }
                            }}
                            className="btn  btn-secondr btn-lg btn-block"
                            disabled={loading}
                            style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                        >
                            Geri
                        </button>
                    </div>
                    <div style={{ marginTop: 20 }} className="col-md-6">
                        <button
                            onClick={SaveForm}
                            className="btn  btn-primary btn-lg btn-block"
                            disabled={loading}
                            style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                        >
                            İleri
                        </button>
                    </div>
                    <div className="col-md-12">
                        {
                            danger ?
                                <div style={{ marginTop: "25%" }} className="alert alert-danger" role="alert">
                                    {!wrongJob ? "Lütfen belirtilen alanları eksiksiz ve doğru bir şekilde seçiniz." : "Mesleğinizi Diğer veya Serbest yerine net ismiyle belirtmeniz gerekmektedir."}
                                </div> : void 0}

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default EpisodeThree
