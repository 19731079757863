import React, { useState, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import StepWizard from "react-step-wizard";
import globalStates from "../../../../context/globalStates";
import GlobalStates from "../../../../context/globalStates";
import useRequest from "../../../../hooks/useRequest";
import { customFetch } from "../../../../hooks/customFetch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import moment from "moment";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { decryptData } from "../../../../helper/helper";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy");

const Step1 = (props) => {
  const { innerTexts, tcKimlikNu } = useContext(globalStates);

  registerLocale("tr", tr);

  const Part1 = (props) => {
    const [idSerialNuUserCompanies, setIdSerialNuUserCompanies] = useState(
      innerTexts.addresVerify
    );
    const [danger, setDanger] = useState({ valid: false, message: "" });
    const { startFetch, response, URL, loading } = useRequest();
    const [cookie] = useCookies(["token"]);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState(""); // const [birthday, setBirthday] = useState("");
    const [gender, setGender] = useState(null);
    const [relationState, setRelationState] = useState(null);
    const { slugify } = useContext(GlobalStates);
    const [birthday, setBirthday] = useState();
    const [idSerialNu, setIdSerialNu] = useState("");
    const [tipVisible, setTipVisible] = useState(false);
    const [firstCheckDone, setFirstCheckDone] = useState(false);
    const [birthplace, setBirthPlace] = useState("");

    const [pickBranch, setPickBranch] = useState("");
    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
      if (response) {
        if (response.errNo === 0) {
          if (response.bilgiler) {
            var data = { ...response.bilgiler };
            setName(data.ad);
            setLastname(data.soyad);
            setBirthPlace(data.dogum_yeri);
            sessionStorage.setItem("first_name", slugify(data.ad));
            sessionStorage.setItem("last_name", slugify(data.soyad));
  
            if (data.dogum_tarihi !== "") {
              sessionStorage.setItem("bd", data.dogum_tarihi);
              let birthDateArr = data.dogum_tarihi.split("-");
              var dobj = new Date(
                parseInt(birthDateArr[0]),
                parseInt(birthDateArr[1]) - 1,
                parseInt(birthDateArr[2])
              );
              setBirthday(dobj);
            }

            setGender(data.cinsiyet);
            setRelationState(data.medeni_durum);
            setIdSerialNu(data.kimlik_serino);
          } else {
            if (response.subeler) {
              var data = response.subeler;
              setBranchList(data);
              return;
            }
            if (!firstCheckDone && idSerialNuUserCompanies) {
              return;
            } else {
              if (
                innerTexts.id === "OSMANLIYATIRIM" ||
                innerTexts.id === "ISIKMENKUL"
              ) {
                props.portfoy
                  ? props.nextStep()
                  : props.handleWizard.goToStep(3);
              }
              if(innerTexts.id === 'HEDEF') {
                props.handleWizard.nextStep();
              }
              props.portfoy ? props.nextStep() : props.handleWizard.nextStep();
            }
          }
        }
      }
    }, [response]);

    useEffect(() => {
      startFetch("islem=kimlik_bilgileri_getir&step=1", "GET");
    }, []);

    useEffect(() => {
      if (innerTexts.id === "INFOYATIRIM") {
        startFetch("islem=subeler", "GET");

        customFetch({
          params: `islem=yatirimci_bilgileri_getir`,
          method: "GET",
          contentType: null,
          body: null,
          onSuccess: (res) => {
            if (res.errNo === 0) {
              if (res.bilgiler.sube) {
                setPickBranch(res.bilgiler.sube);
              }
            }
          },
        });
      }
    }, []);

    const Validation = async () => {
      var model = {
        islem: "tc_no_check",
        tc_no: parseInt(decryptData(cookie.TC)),
        ad: name,
        soyad: lastname,
        dogum_yili: birthday?.toString().split(" ")[3],
        medeni_durumu: relationState,
        dogum_yeri: birthplace,
      };

      if (innerTexts.gender) {
        model.cinsiyet = gender || "E"
      }

      var params = "";
      for (var I in model) {
        params += "" + I + "=" + model[I] + "&";
      }
      if (
        name === "" ||
        lastname === "" ||
        birthday === "" ||
        (innerTexts.gender && gender === "") ||
        (innerTexts.id === "GALATA" && relationState === "") ||
        (idSerialNuUserCompanies && innerTexts.id !== 'ISTANBULPORTFOY' && innerTexts.id !== 'HEDEF' && idSerialNu === "") ||
        ((innerTexts.id === "DINAMIK" || innerTexts.id === 'ISTANBULPORTFOY') && birthplace === "")
      ) {
        setDanger({
          valid: true,
          message: innerTexts.error_messages_empty_tckn,
        });
        return false;
      } else {
        const request = await fetch(URL + params, {
          method: "POST",
          headers: {
            Authorization: cookie.token,
          },
        });

        const data = await request.json();
        if (data.tc_no_check === "true") {
          setDanger({ valid: false, message: "" });
          return true;
        }
        setDanger({
          valid: true,
          message: innerTexts.error_messages_wrong_tckn,
        });
        return false;
      }
    };

    const saveForm = async () => {

      var errorElement = window.jQuery("html");
      var scrollPosition = errorElement.offset().top - 25;
      window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

      setFirstCheckDone(false);
      let birthDate =
        birthday?.getFullYear() +
        "-" +
        (birthday?.getMonth() + 1) +
        "-" +
        birthday?.getDate();
      var model = {
        islem: "kimlik_bilgileri",
        step: 1,
        ad: slugify(name),
        soyad: slugify(lastname),
        cinsiyet: gender || "E",
        dogum_tarihi: birthDate,
        medeni_durumu: relationState,
        dogum_yeri: birthplace,
      };
      if (innerTexts.id === "DINAMIK") {
        model.dogum_yeri = birthplace;
      }
      var params = "";
      for (var I in model) {
        params += "" + I + "=" + model[I] + "&";
      }

      var modelOsmanli = {
        islem: "kimlik_bilgileri_diger",
        step: 1,
      };
      var urlencoded = new URLSearchParams();
      urlencoded.append("kimlik_serino", idSerialNu);

      var paramsOsmanli = "";
      for (var I in modelOsmanli) {
        paramsOsmanli += "" + I + "=" + modelOsmanli[I] + "&";
      }

      if (await Validation()) {
        startFetch(params, "POST", JSON.stringify({}), null, (res) => {
          if (res.errNo === -30) {
            setDanger({
              valid: true,
              message: res.errDesc,
            });
          }
          if (res.errNo === 0) {
            setFirstCheckDone(true);

            // temp for isy
            if (innerTexts.id === "ISTANBULPORTFOY") {
              props.handleWizard.nextStep();
              return;
            }

            if (innerTexts.id === "INFOYATIRIM") {
              if (innerTexts.branch) {
                if (pickBranch === "") {
                  return setDanger({
                    message: innerTexts.error_messages_step_one_episode,
                    valid: true,
                  });
                }
              }
              var paramsInfo = "";
              var modelInfo = {
                islem: "yatirimci_bilgileri_diger",
                sube: pickBranch,
              };

              for (var I in modelInfo) {
                paramsInfo += "" + I + "=" + modelInfo[I] + "&";
              }
              setDanger({ valid: false, message: "" });
              startFetch(paramsInfo, "POST");
            }
          }
          sessionStorage.setItem("first_name", name);
          sessionStorage.setItem("last_name", lastname);
          if (idSerialNuUserCompanies && innerTexts.id !== 'ISTANBULPORTFOY' && innerTexts.id !== 'HEDEF' && res.errNo === 0) {
            startFetch(
              paramsOsmanli,
              "POST",
              urlencoded,
              "application/x-www-form-urlencoded",
              (res) => {
                if (res.errNo === 0) {
                  startFetch(
                    "islem=kimlik_bilgileri_getir&step=1",
                    "GET",
                    null,
                    false,
                    (res) => {
                      // disabled the second fetch to /adres/adresdogrulama/no/tc/bd-d/bd-m/bd-y because kps disabled this service
                      // if (res.errNo === 0) {
                      //   if(res?.bilgiler?.adres_no) {
                      //       const addressNo = res.bilgiler.adres_no
                      //       const tc = decryptData(cookie.TC)
                      //       // fetch(`${window.location.origin}/adres/adresdogrulama/${addressNo}/${tc}/${birthday?.getDate()}/${(birthday?.getMonth() + 1)}/${birthday?.getFullYear()}`, {
                      //       //   method: 'GET',
                      //       //   headers: {
                      //       //     'Content-Type': 'application/json; charset=UTF-8',
                      //       //   },
                      //       // })
                      //       // .then((res) => {
                      //       //   console.log('res  final--', res)
                      //       // }).catch((err) => {
                      //       //   console.log('err  final--', err)
                      //       // })
                      //   }
                      // }
                    }
                  );
                  return;
                }
                setDanger({
                  valid: true,
                  message: innerTexts.error_messages_wrong_tckn,
                });
              }
            );
          }
        });
        sessionStorage.setItem("bd", birthDate);
      }
    };

    return (
      <>
        <div className="col-md-12">
          <div
            className="col-md-12"
            style={{ textAlign: "center", marginBottom: 20 }}
          >
            <h2>{innerTexts.label_step_one}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: innerTexts.id !== "OSMANLIYATIRIM" ? innerTexts.label_step_one_description_html : innerTexts.label_step_one_warning_html,
              }}
            ></p>
          </div>
        </div>
        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {" "}
                {innerTexts.label_step_one_input_name}
              </label>
            </div>
            <input
              onChange={(e) => {
                if (
                  e.target.value.charCodeAt(e.target.value.length - 1) ===
                    105 ||
                  e.target.value.charCodeAt(e.target.value.length - 1) === 304
                ) {
                  e.target.value =
                    e.target.value.slice(0, e.target.value.length - 1) + "İ";
                  setName(e.target.value.toLocaleUpperCase());
                } else {
                  setName(e.target.value.toLocaleUpperCase());
                }
              }}
              value={name.toLocaleUpperCase()}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="text"
              required="required"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_lastname}
              </label>
            </div>
            <input
              onChange={(e) => {
                if (
                  e.target.value.charCodeAt(e.target.value.length - 1) ===
                    105 ||
                  e.target.value.charCodeAt(e.target.value.length - 1) === 304
                ) {
                  e.target.value =
                    e.target.value.slice(0, e.target.value.length - 1) + "İ";
                  setLastname(e.target.value.toLocaleUpperCase());
                } else {
                  setLastname(e.target.value.toLocaleUpperCase());
                }
              }}
              value={lastname.toLocaleUpperCase()}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="text"
              required="required"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>
        {innerTexts?.gender && (
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {innerTexts.label_step_one_input_gender}
                </label>
              </div>
              <select
                className="form-control"
                value={gender}
                placeholder="Seçiniz"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option disabled value="">
                  Seçiniz
                </option>
                <option value="E">Erkek</option>
                <option value="B">Kadın</option>
              </select>
            </div>
          </div>
        )}
        {innerTexts.id === "GALATA" && (
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {innerTexts.label_step_one_input_relation_state}
                </label>
              </div>
              <select
                className="form-control"
                value={relationState}
                defaultValue={relationState}
                onChange={(e) => {
                  setRelationState(e.target.value);
                }}
              >
                <option disabled value="">
                  Seçiniz
                </option>
                <option value="E">EVLİ</option>
                <option value="B">BEKAR</option>
                <option value="D">DUL</option>
              </select>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_birthday}
              </label>
            </div>
            {/* <input
              onChange={(e) => {
                setBirthday(e.target.value);
              }}
              defaultValue={birthday}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="date"
              required="required"
              className="form-control"
              placeholder=""
            /> */}
            <DatePicker
              maxDate={new Date(2100, 1, 29)}
              wrapperClassName="birthDateInput"
              required="required"
              className="form-control"
              showYearDropdown
              yearDropdownItemNumber={80}
              scrollableYearDropdown
              selected={birthday}
              onChange={(date) => {
                setBirthday(date);
              }}
              locale="tr"
              dateFormat="dd-MM-yyyy"
              placeholderText="gün-ay-yıl"
              customInput={
                <MaskedInput
                  pipe={autoCorrectedDatePipe}
                  mask={[
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  keepCharPositions={true}
                  guide={true}
                />
              }
            />
          </div>
        </div>
        {innerTexts.id === "INFOYATIRIM" && (
          <div className="col-md-12">
            <div
              style={{ display: "block", zIndex: 2 }}
              className="input-group input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {" "}
                  {innerTexts.label_pickup_branch}
                </label>
              </div>
              <div>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPickBranch(e.target.value);
                  }}
                  value={pickBranch}
                >
                  <option value="">Seçiniz</option>

                  {branchList &&
                    branchList.map((item) => (
                      <option value={item.key}>{item.value}</option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {innerTexts.id === "DINAMIK" || innerTexts.id === "ISTANBULPORTFOY" && (
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {innerTexts.label_step_one_input_birthplace}
                </label>
              </div>
              <input
                onChange={(e) => {
                  if (
                    e.target.value.charCodeAt(e.target.value.length - 1) ===
                      105 ||
                    e.target.value.charCodeAt(e.target.value.length - 1) === 304
                  ) {
                    e.target.value =
                      e.target.value.slice(0, e.target.value.length - 1) + "İ";
                    setBirthPlace(e.target.value.toLocaleUpperCase());
                  } else {
                    setBirthPlace(e.target.value.toLocaleUpperCase());
                  }
                }}
                defaultValue={birthplace}
                value={birthplace}
                aria-describedby="inputGroup-sizing-lg"
                maxLength="100"
                type="text"
                required="required"
                className="form-control"
                placeholder=""
              />
            </div>
          </div>
        )}
        {idSerialNuUserCompanies && innerTexts.id !== 'ISTANBULPORTFOY' && innerTexts.id !== 'HEDEF' && (
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div
                className="serialnu-tip"
                style={{ visibility: tipVisible ? "visible" : "hidden" }}
              >
                {
                  innerTexts.id === 'INVESTAZ' ? (
                    <>
                      <h6>Yeni tip kimlik kartlarında seri numarası formatı A12A12345</h6>
                    </>
                  ) : (
                    <>
                      <h6>Yeni tip kimlik kartlarında seri nu formatı: A12345678</h6>
                      <h6>Eski tip kimlik kartlarında seri nu formatı: A12A12345</h6>
                    </>
                  )
                }
              </div>
              <div
                className="input-group-prepend"
                onClick={() => setTipVisible(!tipVisible)}
              >
                <span
                  className="input-group-text"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "help",
                  }}
                >
                  <i className="fa fa-question-circle fa-lg text-danger"></i>
                </span>
                <label className="control-label">
                  {innerTexts.label_step_one_input_id_serial_nu}
                </label>
              </div>
              <input
                onChange={(e) => {
                  if (
                    e.target.value.charCodeAt(e.target.value.length - 1) ===
                      105 ||
                    e.target.value.charCodeAt(e.target.value.length - 1) === 304
                  ) {
                    e.target.value =
                      e.target.value.slice(0, e.target.value.length - 1) + "İ";
                    setIdSerialNu(e.target.value.toLocaleUpperCase());
                  } else {
                    setIdSerialNu(e.target.value.toLocaleUpperCase());
                  }
                }}
                defaultValue={idSerialNu}
                value={idSerialNu}
                aria-describedby="inputGroup-sizing-lg"
                maxLength="9"
                type="text"
                required="required"
                className="form-control"
                placeholder="Kimlik Seri No"
              />
            </div>
          </div>
        )}
        <div className="col-md-12">
          {danger.valid ? (
            <div
              style={{ marginTop: 10 }}
              className="alert alert-danger"
              role="alert"
            >
              {danger.message}
            </div>
          ) : (
            void 0
          )}
        </div>
        <div className="col-md-12">
          <button
            style={{
              marginTop: 20,
              filter: loading ? "brightness(120%)" : "",
              cursor: loading ? "no-drop" : "pointer",
            }}
            onClick={() => {
              saveForm();
            }}
            className="btn btn-primary nextBtn pull-right btn-block btn-lg"
            disabled={loading}
            type="button"
          >
            {loading ? "Yükleniyor..." : innerTexts.btn_text_step_one}
          </button>
        </div>
      </>
    );
  };

  const Part2 = (props) => {
    const [danger, setDanger] = useState({ valid: false, message: "" });
    const [birthplace, setBirthPlace] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [relationState, setRelationState] = useState("");
    const [motherName, setMotherName] = useState("");
    const [nationality, setNationality] = useState("Türk");
    const [idSerialNu, setIdSerialNu] = useState("");
    const [tipVisible, setTipVisible] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setTipVisible(false);
      }, 5000);
    }, [tipVisible]);

    const { startFetch, response, URL, loading } = useRequest();

    const saveForm = async () => {
      
      var errorElement = window.jQuery("html");
      var scrollPosition = errorElement.offset().top - 25;
      window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

      var model = {
        islem: innerTexts.id === 'ISTANBULPORTFOY' || innerTexts.id === 'HEDEF' ?  "kimlik_bilgileri_diger" : "kimlik_bilgileri",
        step: 1,
      };

      var urlencoded = new URLSearchParams();

        // model.dogum_yeri = birthplace;
        // model.baba_adi = fatherName;
        // model.anne_adi = motherName;
        // model.medeni_durumu = relationState;
        // model.uyruk_bilgisi = nationality;
        // model.kimlik_serino = idSerialNu;

        urlencoded.append("dogum_yeri", birthplace);
        urlencoded.append("baba_adi", fatherName);
        urlencoded.append("anne_adi", motherName);
        urlencoded.append("medeni_durumu", relationState);
        urlencoded.append("uyruk_bilgisi", nationality);
        if (innerTexts.id !== "OPTIMUS") {
          urlencoded.append("kimlik_serino", idSerialNu);
        }




      var params = "";
      for (var I in model) {
        params += "" + I + "=" + model[I] + "&";
      }
      if (Validation()) {
        startFetch(
          params,
          "POST",
          urlencoded,
          "application/x-www-form-urlencoded",
          () => {
            setDanger({
              valid: true,
              message: innerTexts.error_messages_wrong_tckn,
            });
          }
        );
      }
    };
    const Validation = () => {
      if (
        fatherName === "" ||
        birthplace === "" ||
        relationState === "" ||
        motherName === "" ||
        nationality === "" ||
        (innerTexts.id !== "OPTIMUS" && innerTexts.addresVerify && idSerialNu === "")
      ) {
        setDanger({
          valid: true,
          message: innerTexts.error_messages_empty_tckn,
        });
        return false;
      }
      return true;
    };
    useEffect(() => {
      startFetch("islem=kimlik_bilgileri_getir&step=1", "GET");
    }, []);

    useEffect(() => {
      if (innerTexts.id === "INFOYATIRIM") {
        window.gtag("config", "AW-468162055");
        window.gtag("event", "conversion", {
          send_to: "AW-468162055/SVYECP27s8MDEIesnt8B",
        });
      }
    }, []);

    useEffect(() => {
      if (response) {
        if (response.errNo === 0) {
          if (response.bilgiler) {
            var data = { ...response.bilgiler };
            setBirthPlace(data.dogum_yeri);
            setFatherName(data.baba_adi);
            setRelationState(data.medeni_durum);
            setMotherName(data.anne_adi);
            if (innerTexts.id !== "OPTIMUS") {
              setIdSerialNu(data.kimlik_serino);
            }
            // setNationality(data.uyruk_bilgisi)
          } else {
            if (
              innerTexts.id === "OSMANLIYATIRIM" ||
              innerTexts.id === "ISIKMENKUL"
            ) {
              props.handleWizard.goToStep(3);
            }
            props.handleWizard.nextStep();
          }
        }
      }
    }, [response]);
    return (
      <>
        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_birthplace}
              </label>
            </div>
            <input
              onChange={(e) => {
                if (
                  e.target.value.charCodeAt(e.target.value.length - 1) ===
                    105 ||
                  e.target.value.charCodeAt(e.target.value.length - 1) === 304
                ) {
                  e.target.value =
                    e.target.value.slice(0, e.target.value.length - 1) + "İ";
                  setBirthPlace(e.target.value.toLocaleUpperCase());
                } else {
                  setBirthPlace(e.target.value.toLocaleUpperCase());
                }
              }}
              defaultValue={birthplace}
              value={birthplace}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="text"
              required="required"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>

        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_father_name}
              </label>
            </div>
            <input
              onChange={(e) => {
                if (
                  e.target.value.charCodeAt(e.target.value.length - 1) ===
                    105 ||
                  e.target.value.charCodeAt(e.target.value.length - 1) === 304
                ) {
                  e.target.value =
                    e.target.value.slice(0, e.target.value.length - 1) + "İ";
                  setFatherName(e.target.value.toLocaleUpperCase());
                } else {
                  setFatherName(e.target.value.toLocaleUpperCase());
                }
              }}
              defaultValue={fatherName}
              value={fatherName}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="text"
              required="required"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>

        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_mother_name}
              </label>
            </div>
            <input
              onChange={(e) => {
                if (
                  e.target.value.charCodeAt(e.target.value.length - 1) ===
                    105 ||
                  e.target.value.charCodeAt(e.target.value.length - 1) === 304
                ) {
                  e.target.value =
                    e.target.value.slice(0, e.target.value.length - 1) + "İ";
                  setMotherName(e.target.value.toLocaleUpperCase());
                } else {
                  setMotherName(e.target.value.toLocaleUpperCase());
                }
              }}
              defaultValue={motherName}
              value={motherName}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="100"
              type="text"
              required="required"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>

        {
          innerTexts.id !== "ISTANBULPORTFOY" && 
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div className="input-group-prepend">
                <label className="control-label">
                  {innerTexts.label_step_one_input_relation_state}
                </label>
              </div>
              <select
                className="form-control"
                value={relationState}
                defaultValue={relationState}
                onChange={(e) => {
                  setRelationState(e.target.value);
                }}
              >
                <option>Seçiniz</option>
                <option value="E">EVLİ</option>
                <option value="B">BEKAR</option>
                <option value="D">DUL</option>
              </select>
            </div>
          </div>
        }

        <div className="col-md-12">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">
                {innerTexts.label_step_one_input_nationality}
              </label>
            </div>
            <select
              className="form-control"
              value={nationality}
              defaultValue={nationality}
              onClick={(e) => {
                setNationality(e.target.value);
              }}
              onChange={(e) => {
                setNationality(e.target.value);
              }}
            >
              <option selected value="Türk">
                Türkiye Cumhuriyeti Vatandaşı
              </option>
            </select>
          </div>
        </div>

        {innerTexts.id !== "OPTIMUS" && innerTexts.addresVerify ? (
          <div className="col-md-12">
            <div
              style={{ display: "block" }}
              className="input-group  input-group-lg lg-12"
            >
              <div
                className="serialnu-tip"
                style={{ visibility: tipVisible ? "visible" : "hidden" }}
              >
                <h6>Yeni tip kimlik kartlarında seri nu formatı: A12345678</h6>
                <h6>Eski tip kimlik kartlarında seri nu formatı: A12A12345</h6>
              </div>
              <div
                className="input-group-prepend"
                onClick={() => setTipVisible(!tipVisible)}
              >
                <span
                  className="input-group-text"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "help",
                  }}
                >
                  <i className="fa fa-question-circle fa-lg text-danger"></i>
                </span>
                <label className="control-label">
                  {innerTexts.label_step_one_input_id_serial_nu}
                </label>
              </div>
              <input
                onChange={(e) => {
                  if (
                    e.target.value.charCodeAt(e.target.value.length - 1) ===
                      105 ||
                    e.target.value.charCodeAt(e.target.value.length - 1) === 304
                  ) {
                    e.target.value =
                      e.target.value.slice(0, e.target.value.length - 1) + "İ";
                    setIdSerialNu(e.target.value.toLocaleUpperCase());
                  } else {
                    setIdSerialNu(e.target.value.toLocaleUpperCase());
                  }
                }}
                defaultValue={idSerialNu}
                value={idSerialNu}
                aria-describedby="inputGroup-sizing-lg"
                maxLength="9"
                type="text"
                required="required"
                className="form-control"
                placeholder=""
              />
            </div>
          </div>
        ) : (
          <></>
        )
      }

        <div className="col-md-12">
          {danger.valid ? (
            <div
              style={{ marginTop: 10 }}
              className="alert alert-danger"
              role="alert"
            >
              {danger.message}
            </div>
          ) : (
            void 0
          )}
        </div>
        <div className="col-md-6">
          <button
            style={{
              marginTop: 20,
              filter: loading ? "brightness(120%)" : "",
              cursor: loading ? "no-drop" : "pointer",
            }}
            disabled={loading}
            onClick={() => {
              props.previousStep();
            }}
            className="btn  btn-secondr btn-lg btn-block"
          >
            {innerTexts.btn_text_back}
          </button>
        </div>
        <div className="col-md-6">
          <button
            style={{
              marginTop: 20,
              filter: loading ? "brightness(120%)" : "",
              cursor: loading ? "no-drop" : "pointer",
            }}
            disabled={loading}
            onClick={() => {
              saveForm();
            }}
            className="btn btn-primary nextBtn pull-right btn-block btn-lg"
            type="button"
          >
            {loading ? "Yükleniyor..." : innerTexts.btn_text_step_one}
          </button>
        </div>
      </>
    );
  };
  return (
    <div className="panel-body">
      <div className="container">
        <StepWizard>
          <Part1 portfoy={innerTexts.portfoy} handleWizard={props} />
          {
            // innerTexts.portfoy && innerTexts.id !== 'HEDEF'  && <Part2 handleWizard={props} /> TEMP: isy icin kapatildi
          }
          {innerTexts.portfoy && innerTexts.id !== 'HEDEF' && innerTexts.id !== 'ISTANBULPORTFOY'  && <Part2 handleWizard={props} />}
        </StepWizard>
      </div>
    </div>
  );
};
export default Step1;
