import { getBaseUrl } from "../hooks/useRequest";

const URL = getBaseUrl()

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const customFetch = ({ params, method, body, onSuccess, onError, contentType }) => {
    fetch(URL + params, {
        method: method || 'GET',
        headers: {
            'Content-Type': contentType || 'application/json; charset=UTF-8',
            "Authorization": getCookie('token')
        },
        body,
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    }).then((data) => {
            if (data.errNo === -4) {
                //window.location.replace("/")
            } else if(data.errNo === 0 || data.Step) {
                onSuccess(data)
            } else if(data.Status) {
                onSuccess(data)

                // special case for time information
            } else if(data.Time !== "" && data.Time !== undefined) {
                onSuccess(data)
            } else {
                if(onError) {
                    onError(data)
                }
            }
        }).catch(err => {
        if(onError) {
            onError(err)
        }
    })
}
