import React, { useEffect } from "react";
import Form from "../components/widgets/form";
const Home = (props) => {
  useEffect(() => {
    if (document.getElementById("loader").style.display !== "none") {
      setTimeout(() => {
        document.getElementById("loader").style.display = "none";
      }, 2000);
    }
  }, []);
  return (
    <>
      <div className="container-fluid bg">
        <div className="loader-container" id="loader">
          <div className="holder">
            <div className="la-line-scale la-2x">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text_box">
                <strong>Hemen Hesap Aç!</strong>
              </div>
              <div
                className="steps"
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <div className="row step-boxes">
                  <div className="col-md-3">
                    <div className="step_box green_box">
                      <div>
                        <img
                          style={{ transform: "scale(0.7)" }}
                          src="images/giris.svg"
                          alt=""
                        />
                      </div>
                      <div className="step_text">
                        Sisteme <br /> Giriş Yap
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="step_box">
                      <div>
                        <img
                          style={{ transform: "scale(0.7)" }}
                          src="images/profile.png"
                          alt=""
                        />
                      </div>
                      <div className="step_text">Kişisel Bilgiler</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="step_box">
                      <div>
                        <img
                          style={{ transform: "scale(0.7)" }}
                          src="images/sozlesme2.png"
                          alt=""
                        />
                      </div>
                      <div className="step_text">Görüntülü Görüşme</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="step_box">
                      <div>
                        <img
                          style={{ transform: "scale(0.8)" }}
                          src="images/contract.png"
                          alt=""
                        />
                      </div>
                      <div className="step_text">Belgelerim</div>
                    </div>
                  </div>
                </div>
                <div className="row step-balls">
                  <div className="col-md-3 ball-container">
                    <div className="ball"></div>
                  </div>
                  <div className="col-md-3 ball-container">
                    <div className="ball  white-ball"></div>
                  </div>
                  <div className="col-md-3 ball-container">
                    <div className="ball  white-ball"></div>
                  </div>
                  <div className="col-md-3 ball-container">
                    <div className="ball  white-ball last-ball"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_box">
                <strong style={{ textAlign: "left", fontSize: 18 }}>
                  Bilgilerinizi eksiksiz bir şekilde girerek Dijital Hesap
                  Açılış sürecinizi başlatabilirsiniz
                </strong>
                <br />
                <Form {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid work_box">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <span>
                <img src="images/giris.svg" alt="" />
                <strong>Giriş Yapınız</strong>
              </span>
              <p>
                Anketimizi doldurup. Size uygun hizmetlerimizden dilediğinizi
                seçebilirsiniz.
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              <span>
                <img src="/images/sozlesme.svg" alt="" />
                <strong>Sözleşme Onayı</strong>
              </span>
              <p>
                Sözleşmenizi şubeye gelmeden online olarak tamamlayabilirsiniz.
                Ayrıca canlı destek bölümünden size yardımda bulunabiliriz.
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              <span>
                <img src="/images/onay.svg" alt="" />
                <strong>Hesabınızı Kullanmaya Başlayın</strong>
              </span>
              <p>
                Adresinize göndereceğimiz belgeleri imzaladıktan hemen sonra
                işlem yapmaya başlayabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
