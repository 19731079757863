import React, { useContext, useEffect, useRef, useState } from 'react'
import globalStates from '../../../../context/globalStates'
import useRequest from '../../../../hooks/useRequest'
import StepIdentity from './stepIdentity'
import moment from "moment";
import { useCookies } from "react-cookie";
import { customFetch } from "../../../../hooks/customFetch";
import CustomAgreement from '../../CustomAgreement'
import 'moment/locale/tr';
import { decryptData } from '../../../../helper/helper';

const Cases = ["NUFUS_CUZDAN_ON", "NUFUS_CUZDAN_ARKA", "ADRES_TEYIT", "SOZLESME_BIR", "SOZLESME_IKI", "SOZLESME_UC"]
const Step4 = (props) => {
	const { agreementsData } = useContext(globalStates)

    function checkMobileDevice() {
        const userAgent = navigator.userAgent;
        return /Mobi|Android/i.test(userAgent);
      }

    const [isMobile, setIsMobile] = useState(checkMobileDevice())

    const [cookie] = useCookies(["tc"])
    const [notAllowBack, setNotAllowBack] = useState(false);
    const [wait, setWait] = useState(0);
    const [kuryeDone, setKuryeDone] = useState(false)
    const [cityCode, setCityCode] = useState("")
    const [cityName, setCityName] = useState("")
    const [cities, setCities] = useState([{"Kod": 0, "Ad": ""}])
    const [district, setDistrict] = useState("")
    const [districts, setDistricts] = useState([{"Kod": 0, "Ad": ""}])
    const [neighbourhood, setNeighbourhood] = useState("")
    const [addressDetail, setAddressDetail] = useState("")
    const [kuryeErrorMessage, setKuryeErrorMessage] = useState("Bir hata ile karşılaşıldı!")
    const [kuryeError, setKuryeError] = useState(false)
    const [isRequestDisabled, setIsRequestDisabled] = useState(false)

    useEffect(() => {
        if (cities.length > 0) {
          setCityName(cities[0].Ad);
          setCityCode(cities[0].Kod);
        }
      }, [cities]);
    
    useEffect(() => {
      startFetch(`ilcode=${cityCode}&islem=ilce_get`, "GET", null, null, (res)=>{
        if (res?.errNo === 0) {
            setDistricts(res?.il_list)
        }
      })
    }, [cityCode])

    const [customAgreementPopup, setCustomAgreementPopup] = useState(true);

    const uploadCardBodyStyle = {
        minHeight: '32rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#fff'
    }


    const urlencoded = new URLSearchParams();
    urlencoded.append("tc_no", decryptData(cookie.TC));

    useEffect(() => {
        customFetch({
            params: `islem=step_check`,
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            body: urlencoded,
            onSuccess: (res) => {
                if ((innerTexts.id === 'INFOYATIRIM' || innerTexts.id === 'A1CAPITAL' || innerTexts.id === 'INTEGRALYATIRIM') && res.Step === '5') {
                    setNotAllowBack(true)
                }
            }
        })

    }, [props.currentStep])

    useEffect(() => {
              startFetch(`islem=il_get`, "GET", null, null, (res)=>{
                if (res?.errNo === 0) {
                    setCities(res?.il_list)
                }
              })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            kuryeError && setKuryeError(false)
        }, 5000);
    }, [kuryeError])


    const [uploads, setUploads] = useState([])
    const hiddenFileInput = useRef([])
    const [agreementHTML, setAgreementHTML] = useState("<!doctype html><html><head><title>Our Funky HTML Page</title><meta name=\"description\" content=\"Our first page\"><meta name=\"keywords\" content=\"html tutorial template\"></head><body>deneme template<br/><br/><p>AD:TEST</p><br/><p>SOYAD:TEST</p><br/><p>TC_NO:12345678901</p></body></html>")
    const [isContracts, setIsContracts] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [isVideoCall, setIsVideoCall] = useState(false)
    const [isDijitalKurye, setIsDijitalKurye] = useState(false)
    const [smsButtonDisabled, setSmsButtonDisabled] = useState(false)
    const [aksisStatusText, setAksisStatusText] = useState("")
    const [showCompleteMessage, setShowCompleteMessage] = useState(false)
    const { startFetch, ImageUpload, response, loading } = useRequest()
    const [cookies, setCookies] = useCookies(["CEP_NO", 'TC'])
    const { innerTexts } = useContext(globalStates)
    const [shouldSeeAdresTeyit, setShouldSeeAdresTeyit] = useState(process.env.REACT_APP_BUILDTYPE !== "OSMANLIYATIRIM" && process.env.REACT_APP_BUILDTYPE !== "PHILIPCAPITAL" && process.env.REACT_APP_BUILDTYPE !== "INVESTAZ")
    const [shouldSeeContracts, setshouldSeeContracts] = useState(!innerTexts.portfoy || innerTexts.id === 'ISTANBULPORTFOY')

    const checkForwardDisabled = () => {
        const check1 = uploads.NUFUS_CUZDAN_ARKA !== undefined && uploads.NUFUS_CUZDAN_ON !== undefined;
        const check2 = shouldSeeAdresTeyit ? uploads.ADRES_TEYIT !== undefined : true;
        const check3 = shouldSeeContracts && innerTexts.id !== "DINAMIK" ? uploads.SOZLESME_BIR !== undefined && uploads.SOZLESME_IKI !== undefined && uploads.SOZLESME_UC !== undefined : true
        return check1 && check2 && check3;
      };


    const fileUpload = (event, key) => {
        var files = event.target.files
        let file = files[0];
        if (!innerTexts.uploadPDF) {
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                return alert("Dosya Formatı JPG veya PNG olmak zorundadır.");
            }
        } else {
            if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "application/pdf") {
                return alert("Dosya Formatı JPG, PNG veya PDF olmak zorundadır.");
            }

        }

        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            var imageURL = file.type === "application/pdf" ? "/images/nullpdf.jpg" : 'data:image/jpg;base64,' + window.btoa(reader.result);
            var params = ""
            var model = {
                islem: "image_upload",
                image_column: key,
                step: 4,
                dosya_uzantisi: file.type === "application/pdf" ? "pdf" : file?.name?.split('.').pop(),
            }
            for (var I in model) {
                params += '' + I + '=' + model[I] + '&'
            }

            uploads[key] = imageURL
            ImageUpload(params, file, () => {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 2000)
            })
            reader.onerror = function () {
                console.log(reader.error);
            };

        }
    }

    const saveForm = () => {

        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        var params = ""
        var model = {
            islem: "tamamlandi",
            step: 5
        }
        for (var I in model) {
            params += '' + I + '=' + model[I] + '&'
        }
        startFetch(params, "POST")
        props.nextStep()
    }
    const handleFileInput = (index) => {
        hiddenFileInput.current[index].click();
    }

    const downloadAgreement = () => {
        var el = document.createElement("html")
        el.innerHTML = agreementHTML
        window.html2pdf(el, { filename: `${innerTexts.companyName}-SOZLESME.pdf` })
    }

    // osmanli
    const handleCustomVideoCall = () => {
        alert("Video çağrısı başlatıldı.")

        sessionStorage.setItem('w', Date.now())

        var params = ""
        var model = {
            islem: "sms_kod",
        }
        for (var I in model) {
            params += '' + I + '=' + model[I] + '&'
        }

        var urlencoded = new URLSearchParams();
        urlencoded.append("cep_no", decryptData(cookies.CEP_NO));
        urlencoded.append("tc_no", decryptData(cookies.TC));
        urlencoded.append("step", 4);


        startFetch(params, "POST", urlencoded, 'application/x-www-form-urlencoded', (res) => {
            if (res.errNo === -50 || res.errNo === -51 || res.errNo === -52 || res.errNo === -53 || res.errNo === -54) {
                setAksisStatusText(res.errDesc);
            } else if (res.errNo === 0) {
                setIsVideoCall(true)
            }
        })
        setIsVideoCall(true)
        setShowCompleteMessage(true)
    }

    const handleCustomAgreementPopup = () => {
        setCustomAgreementPopup(true)
    }

    const onSubmitAgreement = () => {
        // sign the agreement with Ugur's new agreement api

        //...


        setCustomAgreementPopup(false)
        setIsContracts(true)
    }

    const onCloseAgreement = () => {
        setCustomAgreementPopup(false)
        setIsContracts(false)
    }


    function submitMSG(valid, msg) {
        if (valid) {
            var msgClasses = "success form-message";
        } else {
            var msgClasses = "error form-message";
        }
        window.jQuery("#msgSubmit").removeClass().addClass(msgClasses).text(msg);
    }

    useEffect(() => {
        if (cityName !== "" && district !== "" && neighbourhood !== "" && addressDetail !== "") {
            setKuryeError(false)
            setKuryeErrorMessage("")
            setIsRequestDisabled(false)
        }
    },[cityName, district, neighbourhood, addressDetail])

    const handleDijitalKurye = () => {

        if (cityName === "" || district === "" || neighbourhood === "" || addressDetail === "") {
            setKuryeErrorMessage("Lütfen tüm alanları doldurunuz.")
            setKuryeError(true)
        } else {
            var urlencoded = new URLSearchParams();
            urlencoded.append("adres", `{"il":"${cityName}","ilce":"${district}","mahalle":"${neighbourhood}","adres":"${addressDetail}"}`)
            customFetch({
                params: `islem=dijital_kurye&tc_no=${decryptData(cookies.TC)}&${urlencoded}`,
                method: 'GET',
                onSuccess: (res) => {
                    if (res.errNo === 0) {
                        setKuryeError(false)
                        setKuryeDone(true)
                    } else {
                    setKuryeErrorMessage(res.errDesc)
                    setKuryeError(true)
                    setIsRequestDisabled(false)
                    }
                },
                onError: (err) => {
                    setKuryeErrorMessage(err.errDesc)
                    setKuryeError(true)
                    setIsRequestDisabled(false)
                }
            })
        }
    }

    const handleVideoCallClick = () => {

        sessionStorage.setItem('w', Date.now())

        let dateString;
        let timeOfDay;
        let weekDay;

        let timeOff = '';

        customFetch({
            params: 'islem=time_information',
            method: 'GET',
            onSuccess: (response) => {
                if (response.Time !== undefined && response.Time !== null && response.Time !== '') {
                    dateString = response.Time
                    // dateString = '2024-03-18T16:30:21.9706713+03:00' // for test purposes
                    timeOfDay = moment(dateString).format('HH:mm')
                    weekDay = moment(dateString).locale('tr').format("ddd");
                    console.log('weekDay +++', weekDay);
                    
                    if (innerTexts.aksisWeekdaysTimes !== undefined && innerTexts.aksisWeekdaysTimes !== null && innerTexts.aksisWeekdaysTimes !== '') {

                        let dayResult = innerTexts.aksisWeekdaysTimes.find((item) => {
                            if (item.day === weekDay && item.active) {
                                return item
                            }
                        });

                        if (dayResult && dayResult.active) {
                            if (!dayResult.start2 || !dayResult.end2) {
                                if (moment(timeOfDay, 'HH:mm').isBefore(moment(dayResult.start, 'HH:mm')) || moment(timeOfDay, 'HH:mm').isAfter(moment(dayResult.end, 'HH:mm'))) {
                                    innerTexts.id !== 'INFOYATIRIM' ?
                                    timeOff = `Görüntülü görüşme servisi ${moment(dateString).format('dddd')} günü için ${dayResult.start} - ${dayResult.end} saatleri arasında aktiftir. Lütfen çalışma saatlerinde tekrar deneyiniz.` :
                                    timeOff = `Görüntülü görüşme servisi calışma saatlerinde hizmet vermektedir. Müşteri Temsilciniz çalışma saatleri içinde size ulaşıp işlemi tamamlamanız yardımcı olacaktır.`
                                } else {
                                    timeOff = '';
                                }



                            } else {
                                if (moment(timeOfDay, 'HH:mm').isBefore(moment(dayResult.start, 'HH:mm')) || moment(timeOfDay, 'HH:mm').isAfter(moment(dayResult.end2, 'HH:mm')) || (moment(timeOfDay, 'HH:mm').isAfter(moment(dayResult.end, 'HH:mm')) && moment(timeOfDay, 'HH:mm').isBefore(moment(dayResult.start2, 'HH:mm')))) {
                                    innerTexts.id !== 'INFOYATIRIM' ?
                                    timeOff = `Görüntülü görüşme servisi ${moment(dateString).format('dddd')} günü için ${dayResult.start} - ${dayResult.end} ve ${dayResult.start2} - ${dayResult.end2} saatleri arasında aktiftir. Lütfen çalışma saatlerinde tekrar deneyiniz.` :
                                    timeOff = `Görüntülü görüşme servisi calışma saatlerinde hizmet vermektedir. Müşteri Temsilciniz çalışma saatleri içinde size ulaşıp işlemi tamamlamanız yardımcı olacaktır.`
                                } else {
                                    timeOff = ""
                                }
                            }
                        } else {
                            timeOff = `Görüntülü görüşme servisi ${moment(dateString).format('dddd')} günü için aktif değildir.`
                        }
                    }

                    if (timeOff !== '') {
                        alert(timeOff)
                    } else {
                        var params = ""
                        var model = {
                            islem: "sms_kod",
                        }

                        var urlencoded = new URLSearchParams();

                        urlencoded.append("cep_no", decryptData(cookies.CEP_NO));
                        urlencoded.append("tc_no", decryptData(cookies.TC));

                        if (innerTexts.id === 'OSMANLIYATIRIM' || innerTexts.id === 'ISIKMENKUL' || innerTexts.id === 'DINAMIK' || innerTexts.id === 'HEDEF') {
                            urlencoded.append("step", 4);
                        }


                        // if (innerTexts.id === 'OSMANLIYATIRIM') {
                        //     model.step = 4
                        // }

                        for (var I in model) {
                            params += '' + I + '=' + model[I] + '&'
                        }

                        params = params.slice(0, -1)

                        setWait(innerTexts.aksisWaitTime || 30)
                        startFetch(params, "POST", urlencoded, 'application/x-www-form-urlencoded', (res) => {
                            if (res.errNo === -50 || res.errNo === -51 || res.errNo === -52 || res.errNo === -53 || res.errNo === -54) {
                                setAksisStatusText(res.errDesc);
                            } else if (res.errNo === 0) {
                                setIsVideoCall(true)
                            }
                        })
                    }
                }
            }
        })
    }


    
    useEffect(() => {
        if (!innerTexts.verifyIdentity) {
            setIsContracts(true)
        }
    }, [loading])

    function addScript(url) {
        var script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = url;
        script.id = 'templateGetirScript'
        document.head.appendChild(script);
    }


    useEffect(() => {
        if (props.currentStep === 4 && innerTexts.id !== 'HEDEF') {
            if (response) {
                if (response.errNo === 0) {
                    if (response.image) {
                        uploads[response.image_column] = response.image_extension === ".pdf" ? "/images/nullpdf.jpg" : `data:image/${response.image?.split('.')[1]};base64,` + response.file
                        setUploads(uploads)
                        return
                    }
                    if (response.bilgiler) {

                        if (response.bilgiler.template) {
                            setAgreementHTML(response.bilgiler.template)
                            return
                        }

                        return
                    }
                }
            } else {
                for (let index = 0; index < Cases.length; index++) {
                    const element = Cases[index];
                    startFetch("islem=image_show&image_column=" + element + "&step=4", "GET")
                }
            }

            startFetch("islem=template_getir&template_sys_no=1&step=4", "GET")

            if(
                document.getElementById('templateGetirScript') === null
            ) {
                addScript('/js/html2pdf.min.js');
            }

        }

    }, [response, props.currentStep])

    useEffect(() => {
        if (props.currentStep === 3 && (innerTexts.id === 'OSMANLIYATIRIM' || innerTexts.id === 'ISIKMENKUL' || innerTexts.id === 'DINAMIK' || innerTexts.id === 'ACAR' || innerTexts.id === 'HEDEF')) {
            startFetch("islem=template_getir&template_sys_no=1&step=4", "GET")
        }
    }, [props.currentStep])

    useEffect(() => {
        wait > 0 && setTimeout(() => setWait(wait - 1), 1000);
    }, [wait])

    useEffect(() => {
        let currentTimeStamp = new Date().getTime();
        let lastTimeStamp = sessionStorage.getItem('w');
        if (lastTimeStamp) {
            let diff = currentTimeStamp - lastTimeStamp;
            if (diff < innerTexts.aksisWaitTime * 1000) {
                setWait(innerTexts.aksisWaitTime || 30)
            } else {
                sessionStorage.removeItem('w');
            }
        }

    }, [])

    // osmanli
    if (innerTexts.customSteps && !innerTexts.uploadDocuments && props.currentStep === 3) {


        return (
            <div className="container">
                <div className="row">
                    <div
                        className="col-md-12"
                        style={{ textAlign: "center", marginBottom: 20 }}
                    >
                        {aksisStatusText !== '' && <div className="alert alert-warning" role="alert">
                            {aksisStatusText}
                        </div>}
                        <div className="alert alert-success" role="alert">

                            <h2 className="alert-heading">Görüntülü konuşma adımına devam edebilirsiniz.</h2>
                            <h4>Görüntülü konuşma adımına devam edebilmek için lütfen aşağıdaki butona tıklayınız.</h4>
                            <hr />
                            {
                                showCompleteMessage ? (
                                    <>
                                        <h4>
                                            Bu adımdan sonra artık telefonunuza gelen talimatları takip ederek kimlik doğrulama işlemini gerçekleştirmeniz gerekmektedir. Bu sayfayı kapatabilirsiniz.
                                        </h4>
                                        <br />
                                        <button
                                            onClick={() => {
                                                window.location.replace("" + innerTexts.companyHomePage);
                                            }}
                                            className="btn btn-primary btn-block"
                                        >
                                            Anasayfa'ya Git
                                        </button>
                                    </>



                                ) : (
                                    <button
                                        onClick={() => {
                                            handleCustomVideoCall()
                                        }}
                                        className="btn btn-danger"
                                        disabled={smsButtonDisabled}
                                    >
                                        {smsButtonDisabled ? 'SMS KODUNUZ GÖNDERİLMİŞTİR' : 'SMS KODU GÖNDER'}
                                    </button>
                                )


                            }

                        </div>

                        {
                            innerTexts.verifyIdentity ? (
                                <div>evrak yukleme buraya gelecek - diger kurumlar gibi 2 secenek olacaktir, evrak ve gorutulu gorusme</div>
                            ) : (
                                <div></div>
                            )
                        }

                        {/* <button
                        onClick={() => {
                            window.location.replace("" + innerTexts.companyHomePage);
                        }}
                        className="btn btn-primary btn-block"
                    >
                        Anasayfa'ya Git
                    </button> */}
                    </div>
                </div>
            </div>
        )
    }

    // normal with all steps
    if ((!innerTexts.customSteps || innerTexts.uploadDocuments) && (props.currentStep === 4 || props.currentStep === 3)) {
        if (isVideoCall) {
            return (
                <div className="container">
                    <div className="row">
                        <div
                            className="col-md-12"
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <div className="alert alert-success" role="alert">
                                <h2 className="alert-heading" style={{marginBottom:"3em"}}>Görüntülü Konuşma Adımına Devam Edebilirsiniz.</h2>

                                { !isMobile &&
                                <>
                                    <h4 dangerouslySetInnerHTML={{
                                        __html: innerTexts.label_qrcode_desktop_html || "QR kodu kullanarak uygulamayı indirebilirsiniz."
                                    }}></h4>
                                    <img style={{maxWidth: '100%', width: '16em', margin:"1em"}} src="/images/qrcode.png" alt="qrcode-image"/>
                                </>
                                }

                                { isMobile &&
                                <>
                                    <h4 dangerouslySetInnerHTML={{
                                        __html: innerTexts.label_qrcode_mobile_html || "Linkleri kullanarak uygulamayı indirebilirsiniz."
                                    }}></h4>
                                <a href={innerTexts.companyAppLinkIos}>
                                  <img style={{ width: "12em" }} src={require('../../../../assets/app-store-logo.png')} alt="App Store Logo" />
                                </a>
                                <a href={innerTexts.companyAppLinkAndroid}>
                                  <img style={{ width: "12em" }} src={require('../../../../assets/play-store-logo.png')} alt="Play Store Logo" />
                                </a>
                                </>
                                }

                                <hr />
                                {
                                    innerTexts.id === "ISTANBULPORTFOY" || innerTexts.id === 'HEDEF' ?
                                        <p className="mb-0" style={{ marginTop: "3em" }}>
                                            Uzaktan hesap açılış sürecinizde form bölümü tamamlanmıştır. Mevzuat gereği uzaktan hesap açılışlarda görüntülü görüşme ile kimlik doğrulama zorunludur. Telefonunuza görüntülü görüşme için gerekli olan şifre ve uygulamanın linki gönderilmiştir. Aşağıdaki gün ve saat aralıklarında uygulama üzerinden kimlik numaranız ve SMS ile iletilen şifre ile giriş yaparak müşteri temsilcisi ile kimlik doğrulama görüşmesini gerçekleştirebilirsiniz. Görüşme esnasında kimlik kartınızın yanınızda olması gerekmektedir.
                                        </p>
                                        :
                                        <p className="mb-0" style={{ marginTop: "3em" }}>
                                            Bu adımdan sonra artık telefonunuza gelen talimatları takip ederek kimlik doğrulama işlemini gerçekleştirmeniz gerekmektedir. Bu sayfayı kapatabilirsiniz.
                                        </p>
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} className="col-md-6">
                                    {props.finished ? <></> :
                                        <button disabled={notAllowBack} onClick={() => {isVideoCall ? setIsVideoCall(false) : props.previousStep() }} className="btn  btn-secondary btn-lg btn-block">Geri</button>
                                    }
                                </div>

                            {!innerTexts.hideRedirectMainpageButton && <div style={{ marginTop: 20 }} className="col-md-6">
                                    <button
                                        onClick={() => {
                                            window.location.replace("" + innerTexts.companyHomePage);
                                        }}
                                        className="btn  btn-primary btn-lg btn-block"
                                    >
                                        Anasayfa'ya Git
                                    </button>
                            </div>}

                            {/* <button
                                onClick={() => {
                                    window.location.replace("" + innerTexts.companyHomePage);
                                }}
                                className="btn btn-primary btn-block"
                            >
                                Anasayfa'ya Git
                            </button> */}
                        </div>
                    </div>
                </div>
            )
        }
        if (isContracts) {
            return (
                <div className="container">
                    <div style={{ left: '50%', transform: 'translate(-50%)', display: showSuccessAlert ? 'block' : 'none', zIndex: 99999999, width: '50vw', position: "fixed", top: 20, height: 50 }} className="alert alert-success">İşlem Başarılı!</div>

                    {
                        // innerTexts.id === 'A1CAPITAL' && customAgreementPopup ? (
                        //     <CustomAgreement onClose={onCloseAgreement} onSubmit={onSubmitAgreement} html={response?.bilgiler?.template || 'Sözleşme yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz'} />
                        // ) :

                        (
                            <div className="row">
                                {(!innerTexts.portfoy && innerTexts.id !== 'DINAMIK') && (
                                    <div className="col-md-12" style={{ textAlign: "center", marginBottom: 20 }}>
                                        <h2>{innerTexts.label_step_four_title}</h2>

                                        <p style={{ fontSize: 'larger', color: '#484646' }}>{innerTexts.label_step_four_description}</p>
                                        {
                                            // innerTexts.id !== 'A1CAPITAL' && (
                                                <button
                                                    style={
                                                        innerTexts.id === 'OSMANLIYATIRIM'
                                                            ?
                                                            {
                                                                background: 'linear-gradient(0deg, rgba(250,214,160,0.9) 0%, rgba(233,109,113,.9) 100%)',
                                                                lineHeight: "40px",
                                                                color: "white",
                                                                textTransform: "uppercase",
                                                            }
                                                            :
                                                            {
                                                                lineHeight: "40px",
                                                                backgroundColor: 'green',
                                                                color: "white",
                                                                textTransform: "uppercase",
                                                                border: "1px solid #484646",
                                                            }
                                                    }
                                                    onClick={downloadAgreement}
                                                    className="btn btn-secondary"
                                                >
                                                    {innerTexts.label_step_four_download_contract}
                                                </button>
                                            // )
                                        }

                                    </div>
                                )}


                                <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                    <div className="card">
                                        <div>
                                            <div style={uploadCardBodyStyle} className="card-body">
                                                <img style={{ width: "85px", height: "85px" }} src={uploads.NUFUS_CUZDAN_ON ? uploads.NUFUS_CUZDAN_ON : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                <p>Kimlik Belgenizin ön yüzünü yükleyiniz.</p>
                                                <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                {loading ?
                                                    <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                    : <button onClick={() => { handleFileInput(0) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                }

                                                <input
                                                    type="file"
                                                    ref={r => hiddenFileInput.current[0] = r}
                                                    onChange={(e) => { fileUpload(e, "NUFUS_CUZDAN_ON") }}
                                                    style={{ display: 'none' }} />

                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                    <div className="card">
                                        <div>
                                            <div style={uploadCardBodyStyle} className="card-body">
                                                <img style={{ width: "85px", height: "85px" }} src={uploads.NUFUS_CUZDAN_ARKA ? uploads.NUFUS_CUZDAN_ARKA : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                <p>Kimlik Belgenizin arka yüzünü yükleyiniz.</p>
                                                <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                {loading ?
                                                    <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                    : <button onClick={() => { handleFileInput(1) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                }
                                                <input
                                                    type="file"
                                                    ref={r => hiddenFileInput.current[1] = r}
                                                    onChange={(e) => { fileUpload(e, "NUFUS_CUZDAN_ARKA") }}
                                                    style={{ display: 'none' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {
                                    shouldSeeAdresTeyit &&
                                        <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                            <div className="card">
                                                <div>
                                                    <div style={uploadCardBodyStyle} className="card-body">
                                                        <img style={{ width: "85px", height: "85px" }} src={uploads.ADRES_TEYIT ? uploads.ADRES_TEYIT : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                        <p>Adres teyit belgenizi yükleyiniz.</p>
                                                        <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                        {loading ?
                                                            <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                            : <button onClick={() => { handleFileInput(2) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                        }
                                                        <input
                                                            type="file"
                                                            ref={r => hiddenFileInput.current[2] = r}

                                                            onChange={(e) => { fileUpload(e, "ADRES_TEYIT") }}
                                                            style={{ display: 'none' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                }

                                {
                                    // !innerTexts.portfoy && innerTexts.id !== 'A1CAPITAL' && (
                                        shouldSeeContracts  && innerTexts.id !== "DINAMIK" && innerTexts.id !== "ISTANBULPORTFOY" &&  (
                                        <>
                                            <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                                <div className="card">
                                                    <div>
                                                        <div style={uploadCardBodyStyle} className="card-body">
                                                            <img style={{ width: "85px", height: "85px" }} src={uploads.SOZLESME_BIR ? uploads.SOZLESME_BIR : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                            <p>İmzaladığınız sözleşmenin birinci sayfasının görüntüsü.</p>
                                                            <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                            {loading ?
                                                                <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                                : <button onClick={() => { handleFileInput(3) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                            } <input
                                                                type="file"
                                                                ref={r => hiddenFileInput.current[3] = r}

                                                                onChange={(e) => { fileUpload(e, "SOZLESME_BIR") }}
                                                                style={{ display: 'none' }} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                                <div className="card">
                                                    <div>
                                                        <div style={uploadCardBodyStyle} className="card-body">
                                                            <img style={{ width: "85px", height: "85px" }} src={uploads.SOZLESME_IKI ? uploads.SOZLESME_IKI : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                            <p>İmzaladığınız sözleşmenin ikinci sayfasının görüntüsü.</p>
                                                            <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                            {loading ?
                                                                <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                                : <button onClick={() => { handleFileInput(4) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                            } <input
                                                                type="file"
                                                                ref={r => hiddenFileInput.current[4] = r}
                                                                onChange={(e) => { fileUpload(e, "SOZLESME_IKI") }}
                                                                style={{ display: 'none' }} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ marginBottom: '3rem' }} className="col-md-4">
                                                <div className="card">
                                                    <div>
                                                        <div style={uploadCardBodyStyle} className="card-body">
                                                            <img style={{ width: "85px", height: "85px" }} src={uploads.SOZLESME_UC ? uploads.SOZLESME_UC : "/images/photonull.png"} className="card-img-top" alt="null-image-png" />
                                                            <p>İmzaladığınız sözleşmenin üçüncü sayfasının görüntüsü.</p>
                                                            <p><span style={{ color: "#949494" }}>{innerTexts.label_step_four_upload_text}</span></p>
                                                            {loading ?
                                                                <button className="btn btn-secondary btn-block">Bir fotoğraf yükleniyor. Bekleyiniz...</button>
                                                                : <button onClick={() => { handleFileInput(5) }} className="btn btn-primary btn-block">Fotoğraf Seç</button>
                                                            }   <input
                                                                type="file"
                                                                ref={r => hiddenFileInput.current[5] = r}

                                                                onChange={(e) => { fileUpload(e, "SOZLESME_UC") }}
                                                                style={{ display: 'none' }} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                }


                                <div style={{ marginTop: 20 }} className="col-md-6">
                                    {props.finished ? <></> :
                                        <button disabled={notAllowBack} onClick={() => {isContracts ? setIsContracts(false) : props.previousStep() }} className="btn  btn-secondary btn-lg btn-block">Geri</button>
                                    }
                                </div>

                                <div style={{ marginTop: 20 }} className="col-md-6">
                                    <button
                                        onClick={saveForm}
                                        disabled={ checkForwardDisabled() && !loading ? false : true }
                                        className="btn  btn-primary btn-lg btn-block"
                                    >
                                        {loading ? "Fotoğraflar yüklenirken Bekleyiniz" : "İleri"}
                                    </button>
                                </div>

                            </div>
                        )
                    }

                </div>
            )
        }

        if (kuryeDone) {
            return (<div className="container mt-3" style={{ marginTop: 20 }}>
                <div className="alert alert-success" style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}} role="alert">
                    <h2 className="alert-heading">Dijital Kurye talebiniz alınmıştır.</h2>
                    <h4>Telefonunuza bir SMS gönderdik.</h4>
                    <hr />
                    <h4 className="mb-0">
                    Telefonunuza gelen SMS'deki linke tıklayarak randevu tarihini belirleyip süreci başlatabilirsiniz.
                    </h4>
                </div>
                <div className="col-md-12">
                    <button
                        onClick={() => {
                            setKuryeDone(false)
                            window.location.replace("" + innerTexts.companyHomePage);
                        }}
                        className="btn btn-primary btn-block"
                        style={{ marginTop: "20px", padding: "10px" }}
                    >
                        Anasayfa'ya Git
                    </button>
                </div>
            </div>)
        }

        if (isDijitalKurye && !kuryeDone) {
            return(
                <div className="container mt-3" style={{marginTop: 20}}>
                    <div className="alert alert-warning col-md-12" role="alert" style={{ borderRadius: "10px", width: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.5 6h3l-1 8h-1l-1-8zm1.5 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25" fill="#c09853" />
                        </svg>                        
                        <span style={{ fontWeight: 600, fontSize: "16px", marginLeft: "20px" }}> Kurye, sözleşmeleri size imzalatmak üzere girmiş olduğunuz adrese gelecektir.</span>
                    </div>
                    <div className="col-md-12">
                        <div className='row'>
                            <div className="col-md-4">
                                <div className="input lg-12">
                                    <div className="input-group-prepend" style={{ display: "block" }}>
                                        <label className="control-label">{innerTexts.label_region_short}</label>
                                    </div>
                                    <select
                                        onChange={(e) => {
                                            setCityCode(e.target.value);
                                            setCityName(e.target.options[e.target.selectedIndex].text);
                                        }}
                                        value={cityCode}
                                        className="form-control form-control-lg"
                                        style={{ zIndex: 1 }}
                                        required
                                    >
                                        <option disabled value={null}>{innerTexts.label_pick_city}</option>
                                        {cities.map((x, i) => (
                                            <option key={i} value={x.Kod}>
                                                {x.Ad}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="input lg-12">
                                    <div className="input-group-prepend" style={{ display: "block" }}>
                                        <label className="control-label">{innerTexts.label_district_short}</label>
                                    </div>
                                    <select
                                        onChange={(e) => {
                                            setDistrict(e.target.value);
                                        }}
                                        value={district}
                                        className="form-control form-control-lg"
                                        style={{ zIndex: 1 }}
                                        required
                                    >
                                        <option value={null}>{innerTexts.label_pick_district}</option>
                                        {districts.map((x, i) => (
                                            <option key={i} value={x.Ad}>
                                                {x.Ad}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="inpu lg-12">
                                    <label className="control-label">{innerTexts.label_mah}</label>
                                </div>
                                <input
                                    onChange={(e) => setNeighbourhood(e.target.value)}
                                    className="form-control form-control-lg"
                                    type='text'
                                    required
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 pt-5" style={{ display: "block", marginTop: "30px" }}>
                            <div className="inpu lg-12">
                                    <label className="control-label">{innerTexts.label_address}</label>
                                </div>
                                <textarea
                                    onChange={(e) => setAddressDetail(e.target.value)}
                                    className="form-control col-md-12 mt-5"
                                    style={{ padding: "15px",boxSizing: "border-box" }}
                                    type='text'
                                    rows="7"
                                    placeholder='Cadde/Bina/Daire'
                                    required
                                />
                            </div>
                        </div>

                        {
                        kuryeError &&
                        <div style={{ marginTop: 20 }} className="col-md-12">
                            <div className="alert alert-danger" role="alert" style={{textAlign:"center"}}>
                                <p>{kuryeErrorMessage}</p>
                            </div>
                        </div>
                    }

                        <button
                            onClick={() => {
                                setIsRequestDisabled(true);
                                handleDijitalKurye();
                            }}
                            className="btn btn-primary btn-block"
                            style={{ marginTop: "20px", padding: "10px" }}
                            disabled={isRequestDisabled}
                        >
                            Dijital Kurye İsteği Oluştur
                        </button>
                        <button
                            onClick={()=>{setIsDijitalKurye(false)}}
                            className="btn btn-secondary btn-block"
                            style={{ marginTop: "20px", padding:"10px" }}
                        >
                            Geri
                        </button>
                    </div>
                </div>
            )

        } else {
            return (
                <>
                    <StepIdentity
                        aksisStatusText={aksisStatusText}
                        canUploadDocuments={true}
                        handleDijitalKurye={handleDijitalKurye}
                        setIsDijitalKurye={setIsDijitalKurye}
                        startVideo={handleVideoCallClick}
                        setContract={(e) => {
                            setIsContracts(e)
                            if (response) {
                                if (response?.bilgiler?.template) {
                                    setAgreementHTML(response?.bilgiler?.template)
                                }
                            }
                        }}
                        wait={wait}
                        handleCustomAgreementPopup={handleCustomAgreementPopup}
                    />
                    {
                        wait !== 0 &&
                        <div style={{ marginTop: 20 }} className="col-md-12">
                            <div className="alert alert-danger" role="alert" style={{textAlign:"center"}}>
                                <p>Yeni görüntülü görüşme başlatabilmek için <strong>{wait} saniye</strong> beklemeniz gerekmektedir.</p>
                            </div>
                        </div>
                    }
                </>

            )
        }
    } else {
        return (<div></div>)
    }

}
export default Step4
